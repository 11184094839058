//
// Theme style
//

// Mode
$mode: default;

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import 'layout/layout';
@import './core/layout/docs/layout';

.MuiInputBase-root {
    // font-family: Poppins, Helvetica, 'sans-serif' !important;
    // font-style: ;
}

.form-group {
    .MuiTextField-root {
        width: 80% !important;
    }

    input {
        height: 2em !important;
    }
}

.MuiFormLabel-root,
.MuiStepLabel-label,
.MuiInputBase-root,
.MuiTableCell-root,
.MuiTypography-caption,
.MuiTypography-h5,
.MuiButton-root {
    font-family: Poppins, Helvetica, 'sans-serif' !important;
}

// .MuiButtonBase-root 
.MuiTypography-caption {
    font-size: .8rem !important;
    font-weight: 600 !important;
}

.MuiTablePagination-selectRoot {
    font-size: 1.1rem !important;
    color: rgba(0, 0, 0, 0.54) !important;
    font-weight: 600 !important;
}

.MuiTypography-h5 {
    font-size: 1.1rem !important;
}

.MuiStepLabel-label {
    color: rgba(0, 0, 0, 0.38) !important;
    font-size: 1.2rem !important;
    font-weight: 500 !important;
}


.MuiStepLabel-label.MuiStepLabel-active {
    color: #e4e6ef !important;
    font-size: 1.4rem !important;
    font-weight: 600 !important;

}

.MuiStepConnector-lineHorizontal {
    border-top-width: 5px !important;
}

.MuiStepIcon-text {
    font-size: 1.2rem !important;
    font-weight: 500 !important;
    font-family: unset !important
}

.MuiStepConnector-alternativeLabel {
    top: 12px !important;
    left: calc(-50% + 15px) !important;
    right: calc(50% + 15px) !important;
    position: absolute;
}

.MuiStepLabel-alternativeLabel {

    .MuiSvgIcon-root {
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        height: 2rem;
        width: 2rem;
        background: rgba(0, 0, 0, 0.38);
        border-radius: 50%;
        font-weight: bold;
        color: #FFFFFF;
    }

    .activestep {
        background: #e4e6ef !important;
    }

}

.dasboard-overflow-auto {
    overflow: auto scroll !important;
    max-height: 60px !important;
    min-height: 400px !important;
    min-height: 500px !important;
    overflow-y: auto !important;
}



.makeStyles-textField-12 {
    // width: 80% !important;
}


.stepper-btn {
    text-align: right;
    padding-top: 26px;
}

.MuiButton-root {
    font-size: 1rem !important;
}

.MuiButton-containedPrimary {
    background: #e4e6ef !important;

}

.MuiFormLabel-root.Mui-focused {
    color: #e4e6ef !important;
}

.MuiInput-underline:after {
    border-bottom: 2px solid #e4e6ef !important;
}

.notes-sec {
    width: 80%;
    min-height: 314px;
}

.MuiGrid-item {
    .MuiTableRow-root {
        .MuiInputBase-root {
            border: 1px solid rgba(0, 0, 0, 0.12) !important;
            border-radius: 5px !important;

            &:hover {
                border: 1px solid #e4e6ef !important;
                border-radius: 5px !important;

            }
        }

        .MuiInput-underline:before {
            border-bottom: unset !important;
        }

        .MuiTableCell-root {
            .MuiTextField-root {
                .MuiInput-inputTypeSearch {
                    // font-size: 4rem;

                    &::placeholder {
                        font-size: 1rem;
                    }
                }

            }

        }

    }
}

.MuiSvgIcon-fontSizeSmall {
    font-size: 2rem !important;
}

.MuiTableSortLabel-root:hover {
    // color: #e4e6ef !important;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active {
    // color: #FFFFFF !important;
}

.subheader {
    // position: relative;
    // height: 54px;
    // top: -50px;
    left: 1px;
    right: 0;
    transition: top .3s ease;
    /* z-index: 95; */
    /* box-shadow: 0 10px 30px 0 rgba(82,63,105,.08); */
    // background-color: #fff;
    // border-top: 1px solid #ebedf3;
    margin: 0;
}

.MuiToolbar-root {
    // background-color: #e3e3e3 !important;
}

.MuiTableRow-root {
    // .MuiCheckbox-root {
    //     color: #e4e6ef !important;
    // }

}

.font-size-base {
    font-size: 1.17rem;
}

.text-dark-50 {
    color: #7e8299 !important;
}

.font-weight-bolder {
    font-weight: 600 !important;
}

.vertical_line_left {
    border-left: 1px solid #cfd6db;
    float: left;
    margin-right: 2.2rem;
    margin-left: 2rem;
}

.profile-box {
    text-align: left;
}

.menu_username {
    text-align: left;
    font-size: 1.6rem !important;
    color: #32325c;
}

.header {
    // font-family: Muli,Helvetica,Arial,"sans serif";
    border-bottom: #cfd6db solid .1rem;
    border-radius: 0;
    /* line-height: 3.8rem; */
    height: 6.4rem;
    background: #fff;
    font-size: 1.2rem;
    top: 0;
    height: 80px;
    z-index: 9;
    /* position: fixed; */
    transition: all .3s;
}

.email {
    color: #757575 !important;
}

.card-toolbar {
    .MuiFormControl-root {
        .MuiSelect-select.MuiSelect-select {
            padding-right: 24px !important;
            font-size: 1.2rem !important;
            text-transform: uppercase !important;
        }
    }

    .MuiButton-root {
        font-size: 1rem !important;
        font-weight: 900 !important;
    }

    .MuiGrid-item .MuiInputBase-root {
        border: 1px solid rgba(0, 0, 0, 0.12) !important;
        border-radius: 5px !important;
    }
}

.MuiButton-root:hover {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
}

.MuiTableSortLabel-root:hover {
    color: #1e1e1e !important;
}

.MuiTableSortLabel-root:focus {
    color: #1e1e1e !important;
}

.MuiTableSortLabel-icon:hover {
    color: #e35858 !important;
}

.delete-btn {
    height: 38px !important;
}

.number {
    font-weight: bold;
    color: #009ef7;
    padding-left: 10px;
    font-size: 1.2rem;
}

//Breaddcrumb
.breadcrum-seperator {
    // padding-right: 10px;
    color: #898b96;
    font-weight: 200;
}

.home-icon {
    font-size: 2rem !important;
}

.arrow-icon {
    font-size: 1rem !important;
}

.toolbar-content {
    padding: 15px !important;
}

.breadcrumb .breadcrumb-item,
.fa-plus {
    font-size: 1.1rem !important;
}

.fas {
    font-size: 1rem;

}

.fa-plus {
    color: #FFFFFF;
    padding-right: 4px;
}

.fa-download,
.fa-upload,
.fa-list {
    color: #FFFFFF !important;
    font-size: 1.2rem !important;
    padding-right: 4px !important;
}

.MuiSvgIcon-fontSizeSmall,
.fa-edit,
.fa-times,
.fa-eye,
.fa-bell,
.fa-sticky-note,
.fa-user-plus,
.fa-search,
.add {
    color: #34a7ce !important;
    font-size: 1.1rem;
    padding-right: 10px;
    cursor: pointer;
}

.fa-circle {
    padding-right: 10px;
}

.fa-user-plus {
    padding-left: 10px;
}

.fa-edit,
.bigfont,
.fa-trash-alt {
    font-size: 1.1rem !important;
}

// .fa-upload {
//     // padding-right: 8px;
//     // color: #ffffff;
//     // font-size: 1.4rem;
// }
.fa-bell {
    font-size: 1.6rem !important;
}

.fa-trash-alt {
    color: #e35858;
    cursor: pointer !important;
}

.bulkdelete,
.fa-list {
    color: white !important;
    font-size: 1.2rem;
}

.MuiInput-inputMultiline {
    height: 5rem !important;
}

.my-account {
    .MuiButton-contained {
        background-color: #e4e6ef !important;
    }
}

.MuiButton-contained {
    box-shadow: 0 16px 26px -10px #b5b5c3, 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(63, 106, 216, 0.2)
}

.topbar {
    .container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    Input {
        position: relative;
        padding: 15px 0 15px 20px;
        // width: 2px;
        color: #1e1e1e;
        font-size: 16px;
        font-weight: 100;
        font-size: 16px;
        font-weight: 100;
        border: none;
        // border-radius: 5px;
        border-bottom: none;
        background: linear-gradient(to right, #FFFFFF 0%, #464747 #F9F9F9 100%);
        // transition: width 0.4s ease;
        outline: none;

        // &:focus {
        //     width: 300px;
        // }
    }

    .MuiInput-underline:before {
        border-bottom: 1px solid #e4e6ef !important;
    }
}

// .backbtn {
//     background-color: red !important;
// }
.downshift-dropdown {
    width: 80%;
    border: 1px solid whitesmoke;
    border-bottom: none;
    margin-top: 10px;
}

.dropdown-item {
    padding: 0.5rem;
    cursor: pointer;
    border-bottom: 1px solid whitesmoke;
    font-size: 1rem;
    text-align: left;
}

.dropdown-button {
    padding: 0.6rem;
    border-radius: 3px;
    background: white;
    cursor: pointer;
}

.popper-div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
}

.popper-item {
    padding: .5rem;
    border-bottom: 1px solid whitesmoke;
}


//valiudation
.fv-plugins-message-container {
    margin-top: 0.3rem;

    .fv-help-block {
        color: $danger;
        font-size: 1rem;
        font-weight: 400;
    }

    &.valid-feedback,
    &.invalid-feedback {
        display: block;
        font-weight: 400;
    }
}

.MuiCircularProgress-root {
    width: 25px !important;
    height: 25px !important;
    margin-left: 10px !important;
    color: #FFFFFF !important;
}

.progressbar {
    width: 50px !important;
    height: 50px !important;

}

.alertMsg {
    text-align: center;
    font-size: 20px;
    font-weight: 600;


}

.successMsg {
    color: green;
}

.errorMsg {
    color: red;
}

.steps-content {
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    /* min-height: 200px; */
    text-align: left !important;
    padding-top: 20px;
}

.steps-action {
    /* margin-top: 24px; */
    text-align: right;
}

.MuiToolbar-root {
    // background-color: #00000013 !important;
}

.MuiCircularProgress-root {
    color: green !important;
    // width: 50px !important;
    // height: 50px !important;
}

.cancelBtn {
    background-color: red !important;
    background: red !important;
    color: #FFFFFF !important;
}

.fileupload {

    .MuiStepIcon-root {
        color: #e4e6ef !important;

        .MuiStepIcon-active {
            color: #3f51b5 !important;
        }
    }
}

.uploadIcon {
    width: 7rem !important;
    height: 7rem !important;
}

.fa-percent {
    font-size: 14px;
}

.uploaddocument {
    margin-top: 20px;
}

.MuiInputLabel-shrink {
    font-size: 1.3rem !important;
}

// AG
.ag-theme-alpine {
    font-family: Poppins, Helvetica, 'sans-serif' !important;
    font-size: .8rem !important;

    .ag-header-cell-menu-button {
        display: none !important;
    }

    .ag-row {
        font-size: .8rem !important;
    }

    .ag-floating-filter-button {
        display: none !important;
    }

    .ag-header-row-column {
        font-size: .8rem !important;
        font-weight: 600 !important;
        background-color: rgb(222, 223, 228) !important;
        color: rgb(30, 30, 30) !important;
        text-transform: uppercase !important;
    }

    input[class^=ag-][type=text] {
        &:focus {
            box-shadow: 0 0 2px 0.1rem #e4e6ef !important;
            border-color: #e4e6ef !important;
        }

        &:hover {
            box-shadow: 0 0 2px 0.1rem #e4e6ef !important;
            border-color: #e4e6ef !important;
        }
    }

    .ag-theme-alpine input[class^=ag-][type=text]:hover {
        box-shadow: 0 0 2px 0.1rem #e4e6ef !important;
        border-color: #e4e6ef !important;
    }

    .ag-icon .ag-icon-menu {
        display: none !important;
    }

    .ag-theme-alpine .ag-row {
        height: 46px !important;
    }

    .ag-checkbox-input-wrapper.ag-checked::after {
        color: #f50057 !important;
    }

    .ag-header {
        background-color: unset !important;
        background-color: unset !important;
        border-bottom: unset !important;
        border-bottom-color: unset !important;
    }

    .ag-root-wrapper {
        border: unset !important;
        border-color: #babfc7 !important;
        border-color: unset !important;
    }
}


.builkdelete {
    color: #FFFFFF !important;
}

.checked {
    background: #e4e6ef !important;
    color: #FFFFFF !important;
}

.login-screen {
    margin-bottom: 20px;
}

.footerBar {
    height: 40px;
    font-size: .75rem;
    -webkit-align-items: center;
    align-items: center;
    line-height: 2.5;
    letter-spacing: 1px;
    text-transform: none;
    text-decoration: none;
}

@media (min-width: 2000px) {

    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 100%;
    }
}

@media (min-width: 1440px) {

    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 100%;
    }
}


///
.aside-enabled.aside-fixed .wrapper {
    background: #34a7ce08 !important;
}

.header-card-toolbar {
    display: inline-block;
    width: 100%;

    .card-title {
        float: left;
        padding-top: 20px;
    }

    .btns {
        float: right;
    }
}

.MuiAutocomplete-root {
    /* font-size: 20px; */
    margin-left: 8px;
    /* margin-right: 8px; */
    margin-top: 16px;
    margin-bottom: 8px;
}

//card
.card,
.MuiButton-root {
    border-radius: unset !important;
}

.card {
    .list-body {
        padding: 0 !important;
    }

    .card-header {
        border-bottom: 1px solid #ebedf2 !important;
    }

    .MuiPaper-root {
        box-shadow: unset !important;
    }
}

//button

.agbuttonContainer {
    margin: 0;
    height: 100px;
    padding: 10px;
    display: flex;

    .box {
        justify-content: center;
        align-items: center;
        display: inherit;
    }
}

.text-bold {
    font-weight: bold !important;
}

.card {
    min-width: 100% !important;
}

.ag-body-viewport {
    position: relative !important; // Necessary for perfect scrollbar
}

// .ag-body-horizontal-scroll {
//     height: 15px !important;
//     max-height: 15px !important;
//     min-height: 15px !important;
// }
::-webkit-scrollbar {
    width: 1.8em !important;
    height: 1.8em !important;
}

.dashboard-title {
    margin: 1.1rem !important;
}

.status-icon {
    img {
        width: 40%;
    }
}

.aside-dark .menu .menu-item .menu-link.active .menu-title {
    color: #181c32 !important;
    font-weight: 700 !important;
    letter-spacing: 0 !important;
}

.aside-dark {
    .menu {
        .menu-item {
            .menu-link.active,.menu-link.hover {
                .menu-icon {
                    .svg-icon svg [fill]:not(.permanent):not(g) {
                        fill: #181c32 !important;

                    }
                }
            }
        }

    }
}
.aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
    fill: #181c32 !important;
   
}

.aside-dark .menu .menu-item.hover>.menu-link:not(.disabled):not(.active) .menu-title,
.aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-title {
    color: #181c32;
}

.aside-dark .menu .menu-item .menu-link {
    background-color: #f5f8fa;
    margin-bottom: 10px;
}

.aside-dark .menu .menu-item .menu-link .menu-title {
    color: #7e8299;
    font-weight: 600;

}

.menu-item .menu-link .menu-icon .svg-icon {
    line-height: 1;
    background: transparent;
}

.lead_title {
    font-size: 12px;
}

.lead_desig {
    font-size: 10px;
}

.info_bg {
    border-radius: 15px !important;

}

@media (min-width: 992px) {
    .header-fixed.toolbar-fixed .wrapper {
        padding-top: calc(35px + 55px);
    }
}

// .container {
//     display: flex;
//     width: 100%;
//     height: 100%;
//     align-items: center;
//     justify-content: center;
//     text-align: center;
// }
.sentrequest {
    color: "#000";
    // margin-bottom: 15px;
}

.upcoming_meets {
    /* min-height: 300px; */
    max-height: 640px;
    overflow: scroll;

    .btn-success {
        border: 2px solid #30ce8f7a !important;
        color: #ffffff !important;
        padding: 2px 6px !important;
    }
}

.img-1 {
    width: 100%;
}

.aside-logo a {
    width: 100%;
    text-align: center;
}

.logo-text {
    text-align: center;
    color: black;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 500;
}

.aside-dark {
    .menu .menu-item .menu-link.active {
        background-color: #e9e9eb !important;
        color: #1e1e1e !important;
    }

}