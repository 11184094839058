//
// Login 1
// Pages SASS files are compiled into separate css files
//


// Initialization of global variables, mixins and functions
// Initialize
// @import 'init';

.login.login-1 {

    // Form modes
    .login-signin,
    .login-signup,
    .login-forgot {
        display: none;
    }

    &.login-signin-on {
        .login-signup {
            display: none;
        }

        .login-signin {
            display: block;
        }

        .login-forgot {
            display: none;
        }
    }

    &.login-signup-on {
        .login-signup {
            display: block;
        }

        .login-signin {
            display: none;
        }

        .login-forgot {
            display: none;
        }
    }

    &.login-forgot-on {
        .login-signup {
            display: none;
        }

        .login-signin {
            display: none;
        }

        .login-forgot {
            display: block;
        }
    }
}

.max-h-70px {
    max-height: 70px!important;
}
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .login.login-1 {
        .login-form {
            width: 100%;
            // max-width: 100%;
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .login.login-1 {
        .login-aside {
            width: 100%;
            max-width: 400px;
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .login.login-1 {
        .login-aside {
            width: 100%;
            // max-width: 400px;
        }
        .login-form {
            width: 100%;
            // max-width: 416px;
        }
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .login.login-1 {
        .login-aside {
            width: 100%;
            // max-width: 400px;
        }

        .login-form {
            width: 100%;
            // max-width: 400px;
        }
    }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
    .login.login-1 {
        .login-aside {
            width: 100%;
            // max-width: 400px;
        }

        .login-form {
            width: 100%;
            // max-width: 00px;
        }
    }
}

// // Desktop view
// @include media-breakpoint-up(lg) {
//     .login.login-1 {
//         .login-aside {
//             width: 100%;
//             max-width: 600px;
//         }

//         .login-form {
//             width: 100%;
//             max-width: 400px;
//         }
//     }
// }

// // Tablet and Mobile mode
// @include media-breakpoint-between(lg, xl) {
//     .login.login-1 {
//         .login-aside {
//             width: 100%;
//             max-width: 400px;
//         }
//     }
// }

// // Tablet mode
// @include media-breakpoint-down(md) {
//     .login.login-1 {
//         .login-form {
//             width: 100%;
//             max-width: 350px;
//         }
//     }
// }

// // Mobile mode
// @include media-breakpoint-down(xs) {
//     .login.login-1 {
//         .login-form {
//             width: 100%;
//             max-width: 100%;
//         }
//     }
// }